import React,{useState} from 'react'
import Modal from 'react-modal'
import {Link} from 'gatsby'
import './stileModal.css'

export default function ModalGuidaSito(props){

    const [modalIsOpen,setModalIsOpen]=useState(true)
    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
      };

    return(
        <Modal isOpen={modalIsOpen} onRequestClose={()=>setModalIsOpen(false)} shouldCloseOnOverlayClick={false} style={customStyles}>
        <p className="testoModal">
        Nell'ambito del continuo aggiornamento della piattaforma per la gestione della Ricerca Biomedica, sono stati recentemente effettuati importanti interventi tecnici.
        <br/>
        E'stato effettuato un aggiornamento tecnologico per potenziare l'infrastuttura ed aumentare il livello di continuità di servizio ed il livello di sicurezza nel trattamento dei dati.
        <br/>
        Questo intervento è stato accompagnato anche da un riammodernamento dell'interfaccia utente di accesso ai servizi.
        <br/><br/>
        Sulla home page della piattaforma sono state riorganizzate le funzionalità:<br/><br/>
        <ul>
        <li>
            Attraverso la voce <Link to="/AccessoAlSistemaWorkflow">"Accesso al Sistema Workflow"</Link> è possibile accedere a tutte le funzionalità operative dell'area privata.
        </li>
        <br/>
        <li>
            La voce <Link to="/AreaPubblica">"Documentazione"</Link> permette di consultare tutti i documenti informativi e di supporto messi a disposizione del Ministero.
        </li>
        <br/>
        <li>
        La voce <a href="http://areapubblica.cbim.it/areapubblica/home">"Area Pubblica"</a> da' accesso al sito pe la libera consultazione dei dati del Workflow della Ricerca (realizzato in base al Codice dell'Amministrazione Digitale (art. 68 c. 3 lett. b)).
        </li>
        <br/>
        <li>
        Da ultimo la voce <a href="https://hd.cbim.it/">"Supporto Tecnico"</a> consente di accedere al sistema di gestione delle FAQ e delle richieste di supporto tecnico al servizio di Help Desk di CBIM (Consorzio di Bioingegneria e Informatica Medica)
        </li>
        </ul>
        </p>
        <br/>
        <button onClick={()=>props.chiudiModal()} className="ButtonHome">CHIUDI</button>
        </Modal>
    )
}