import React,{useState} from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet"

import LinkButtonTop from '../components/basicComponents/LinkButtonTop'
import Presentazione from '../components/basicComponents/Presentazione'
import JumbotronFooter from '../components/oneTimeComponents/JumbotronFooter'
import Navbar from '../components/oneTimeComponents/NavigationBar'
import AvvisiBando from '../components/basicComponents/AvvisiBando' 
import AvvisoGuida from '../components/basicComponents/AvvisoGuida'
import {Row,Col} from 'react-bootstrap'
import Modal from '../components/Modal/ModalGuidaSito'
import './stile.css'
import Countdown from "../components/ExtraComponents/Countdown";

export default function Home() {
  const [vediModal,setVediModal]=useState(false)

  const presentazioneContent = <div>
    <br/>
    Il Ministero della salute in attuazione degli interventi individuati nell’ambito del programma “Salute-ambiente-biodiversità-clima” del Piano Nazionale per gli investimenti 
    Complementari al Piano Nazionale di Ripresa e Resilienza,al fine di sviluppare un nuovo assetto istituzionale, funzionale alla tematica 
    “Salute, ambiente, biodiversità e clima”, che possa ridisegnare e rafforzare il Sistema sanitario nazionale – 
    SSN attraverso un approccio olistico One Health, con l’obiettivo di fortificare la protezione della salute dei 
    cittadini rispetto ai rischi attualmente noti e alle sfide incombenti di natura ambientale-climatica e supportare 
    uno sviluppo sostenibile nonché una crescita economica in sicurezza, soprattutto in relazione alla transizione 
    verde e alla trasformazione digitale, finanzia  14 progetti di ricerca applicata rientranti nelle due seguenti 
    macro aree:
    <br/><br/>
    <b>A</b> - Accesso universale all'acqua: approvvigionamento idrico e servizi igienico-sanitari gestiti in sicurezza, uso umano sano e riutilizzo dell'acqua, coste, ambienti marini.<br/>
    <b>B</b> - Prevenzione dei rischi sanitari emergenti associati a cambiamenti ambientali e socio economici, nuove tecnologie, politiche energetiche, trasporti, transizione verde.<br/><br/>
    I 14 progetti risultati finanziabili all’esito dei due avvisi pubblici pubblicati dal Ministero della salute sono:<br/>
    - all’interno dell’AREA A:<br/>
    <ul key="A">
      <li key="A1">
      “Acqua, Clima e Salute: dalla protezione ambientale delle risorse, all’accesso all’acqua, alla sicurezza d’uso (ACeS)” – Capofila Regione Abruzzo
      </li>
      <li key="A2">
      “NecessARIA: necessità di strategie efficienti di ricambio dell'aria per la salute degli occupanti negli edifici scolastici” – Capofila Provincia autonoma di Bolzano
      </li>
      <li key="A3">
      “Aria outdoor e salute: un atlante integrato a supporto delle decisioni e della ricerca” – Capofila Regione Emilia Romagna
      </li>
      <li key="A4">
      “Valutazione dell’esposoma nei primi 1000 giorni in coorti di nati in aree ad elevata antropizzazione e attuazione di interventi per la riduzione del rischio” – Capofila Regione autonoma Friuli Venezia Giulia
      </li>
      <li key="A5">
      “Cobenefici di salute ed equità a supporto dei piani di risposta ai cambiamenti climatici in Italia” – Capofila Regione Lazio
      </li>
      <li key="A6">
      “Monitoraggio abbattImento riSchi Sanitari InquinamentO iNdoor (MISSION)” – Capofila Regione Lombardia
      </li>
      <li key="A7">
      “Impatto dei contaminanti ambientali tossici e persistenti di interesse prioritario nei prodotti ittici del Mar Mediterraneo. Scenari di esposizione alimentare ed effetti sulla salute umana (CAP-fish)” – Capofila Regione Molise
      </li>
      <li key="A8">
      “Il sistema nazionale per il controllo e la sorveglianza dei chemicals a tutela della salute pubblica” – Capofila Regione Puglia
      </li>
      <li key="A9">
      “Valutazione della esposizione e della salute secondo l'approccio integrato One Health con il coinvolgimento delle comunità residenti in aree a forte pressione ambientale in Italia” – Capofila Regione Veneto
      </li>
    </ul>
    <br/>
    - all’interno dell’AREA B:<br/>
    <ul key="B">
      <li key="B1">
      “Il buon uso degli spazi verdi e blu per la promozione della salute e del benessere” – Capofila Regione Calabria
      </li>
      <li key="B2">
      “Biomonitoraggio di micro e nanoplastiche biodegradabili: dall'ambiente all'uomo in una prospettiva one health (BioPlast4SAFE)” – Capofila Regione Campania
      </li>
      <li key="B3">
      “ITWH: sistema gestionale per il benessere e la promozione del Total Worker Health nei luoghi di lavoro” – Capofila Regione Lombardia 
      </li>
      <li key="B4">
      “Portale salute ambiente territoriale per la valutazione del rischio integrato” – Capofila Regione Marche
      </li>
      <li key="B5">
      “Sostenibilità per l'ambiente e la salute dei cittadini nelle città portuali in Italia” – Capofila Regione Puglia
      </li>
    </ul>
    <br/>
    <b>Dotazione finanziaria</b><br/>
    La dotazione finanziaria complessiva disponibile è di 20.766.829,00 €.<br/><br/>
    <br/>Le richieste di assistenza tecnica dovranno essere effettuate esclusivamente mediante il sistema helpdesk di <a href='https://cbim.freshdesk.com/support/home' target='_blank' rel="noopener noreferrer">CBIM</a>.<br/>
    Prima di sottomettere il ticket si prega di consultare le <a href='https://cbim.freshdesk.com/support/solutions'  target='_blank' rel="noopener noreferrer">FAQ</a>.<br/>
  </div>

  const subImgTimer = <div>
  <Countdown lingua="IT" dataFine={1668898500000} dataFineEstesa={"Sabato 19 Novembre alle ore 23:55"} 
  messaggioCountdown={"La fase di presentazione delle domande terminerà tra "}
  messaggioFine={"La fase di presentazione delle domande è terminata "}
  messaggioDateNull={"La fase di presentazione delle domande terminerà "}
  afterCountdown={"."}
  />
  </div>

  const subImg = <div style={{border:"1px solid black",backgroundColor:"#CCE5FF",padding:"5px"}}>
    É possibile presentare le domande per la selezione di due progetti di ricerca 
    applicata “salute-ambiente-biodiversità-clima” per l’attuazione di interventi da finanziare dell’investimento E.1 “Salute-ambiente-biodiversità-clima” del Piano complementare al Piano nazionale di ripresa e resilienza (PNC-E.1) ,
    la scadenza è il <b>19 Novembre 2022 alle ore 23:55</b>.
    <br/><br/>
    {subImgTimer}
  </div>
  
  return (
    <div className="pageContainer">
        <Helmet>
          <title>Workflow della Prevenzione</title>
        </Helmet>
        <Navbar page="IT" sezione="0"/>
        <LinkButtonTop page="IT"/>
        <br/>
        <Presentazione
          titolo="Salute, ambiente, biodiversità e clima (PNC)<br/>INVESTIMENTO E 1.4:  promozione e finanziamento di ricerca applicata con approcci multidisciplinari in specifiche aree di intervento salute-ambiente-clima"
          testo={presentazioneContent}
          subImg={null}
        />
        <div>
          <Row style={{marginTop:"max(0.5vw,20px)"}} >
            <Col sm={6} xs={12}>
              <AvvisiBando lingua="IT" position="L"/>
            </Col>
            <Col sm={6} xs={12}>
              <AvvisiBando lingua="IT" position="R"/>
            </Col>
          </Row>
        </div>
        {vediModal===true?<Modal chiudiModal={()=>setVediModal(false)}/>:null}
        <JumbotronFooter/>
      </div>
    )
}
